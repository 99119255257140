import axios from "axios";
import {Message} from "element-ui";
import router from "@/router";
import Api from "./api"

// const baseURL = "/web";
const baseURL = "/api";

const request = axios.create({
    baseURL,
});


// 请求开始拦截器
request.interceptors.request.use(async function (config) {
    // 如果存在 token，请求头携带这个 token( 登录的时候 把 token 存入了 sessionStorage ）
    if (window.localStorage.getItem("token")) {
       const token=JSON.parse(window.localStorage.getItem('token'))
        config.headers[token.tokenName] = token.tokenValue
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});


// 请求结束拦截器
request.interceptors.response.use(function (success) {
        // 状态码200，确保调到接口,调用到接口后就是我们定义的返回数据的判断了
        if (success.status && success.status === 200) {
            // 401 未登录；跳转登录页（可能是token失效或者没有token）
            if ( success.data.code === 401 ) {
                Message.error({message: success.data.message})
                window.localStorage.removeItem("token")
                router.replace('/')
            }
            //账号密码或者其他参数错误
            else if(success.data.code === 777){
                Message.error({message: success.data.message})
            }
            //500 业务逻辑错误
            else if(success.data.code === 500){
                Message.error({message: success.data.msg})
            }
            else if(success.data.code === 888){
                Message.error({message: success.data.message})
            }
        }
        // 响应没有问题则返回正常的数据（），返回处理后的数据内容，success为原始内容
        return success.data
    },
    async function (err) {
        // console.log(err);
        // return request(err);
    }
);

export const getApi = function(apiName,params,number,type){
    if (Api[apiName]["method"]=="post"){
        return request({
            method: Api[apiName]["method"],
            url: Api[apiName]["url"],
            data: params,
            withCredentials:true,
            headers: {
                'Content-Type':'application/json'
            }
        })
    }
    if (Api[apiName]["method"]=="get") {
        return request({
            method: Api[apiName]["method"],
            url: number?Api[apiName]["url"]+number:Api[apiName]["url"],
            params: params,
            withCredentials: true,
            responseType: type?type:"json",
        })
    }
}

// 抛出供外界使用
export default getApi;
