<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
@media only screen and (max-width: 500px) {
  .el-message-box {
    width: 336px !important;
  }
}
</style>
