import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

//静态路由
export const originroutes = [
    //根路径
    {
        path: '/',
        component: () => import('@/views/layout'),
        meta:{
            title: "重庆市外科学会"
        },
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: 'index',
                component: () => import('@/views/index/index'),
                meta: {label: '首页'}
            },
            {
                path: '/article_detail',
                name: 'article_detail',
                component: () => import('@/views/article_detail'),
                meta: {label: '文章详情'}
            },
            {
                path: '/article_list',
                name: 'article_list',
                component: () => import('@/views/article_list'),
                meta: {label: '文章分页'}
            },
            {
                path: '/about_ssociation',
                name: 'about_ssociation',
                component: () => import('@/views/about_association'),
                meta: {label: '关于学会'}
            },
            {
                path: '/association_introduction',
                name: 'association_introduction',
                component: () => import('@/views/about_association/association_introduction'),
                meta: {label: '学会简介'}
            },
            {
                path: '/association_rules',
                name: 'association_rules',
                component: () => import('@/views/about_association/association_rules'),
                meta: {label: '学会章程'}
            },
            {
                path: '/association_leaders',
                name: 'association_leaders',
                component: () => import('@/views/about_association/association_leaders'),
                meta: {label: '学会领导'}
            },
            {
                path: '/regulations',
                name: 'regulations',
                component: () => import('@/views/about_association/regulations'),
                meta: {label: '规章制度'}
            },
            {
                path: '/contact_us',
                name: 'contact_us',
                component: () => import('@/views/about_association/contact_us'),
                meta: {label: '联系我们'}
            },
            {
                path: '/party_construction',
                name: 'party_construction',
                component: () => import('@/views/party_construction'),
                meta: {label: '党建工作'}
            },
            {
                path: '/party_dongtai',
                name: 'party_dongtai',
                component: () => import('@/views/party_construction/party_dongtai'),
                meta: {label: '党建动态'}
            },
            {
                path: '/study_file',
                name: 'study_file',
                component: () => import('@/views/party_construction/study_file'),
                meta: {label: '学习文件'}
            },
            {
                path: '/organize_manage',
                name: 'organize_manage',
                component: () => import('@/views/organize_manage'),
                meta: {label: '组织管理'}
            },
            {
                path: '/organize_jigou',
                name: 'organize_jigou',
                component: () => import('@/views/organize_manage/organize_jigou'),
                meta: {label: '组织机构图'}
            },
            {
                path: '/specialized_branch',
                name: 'specialized_branch',
                component: () => import('@/views/organize_manage/specialized_branch'),
                meta: {label: '专科分会'}
            },
            {
                path: '/professional_committee',
                name: 'professional_committee',
                component: () => import('@/views/organize_manage/professional_committee'),
                meta: {label: '专业委员会'}
            },
            {
                path: '/work_dongtai',
                name: 'work_dongtai',
                component: () => import('@/views/organize_manage/work_dongtai'),
                meta: {label: '工作动态'}
            },
            {
                path: '/academic_exchange',
                name: 'academic_exchange',
                component: () => import('@/views/academic_exchange'),
                meta: {label: '学术交流'}
            },
            {
                path: '/academic_dongtai',
                name: 'academic_dongtai',
                component: () => import('@/views/academic_exchange/academic_dongtai'),
                meta: {label: '学术动态'}
            },
            {
                path: '/announcement',
                name: 'announcement',
                component: () => import('@/views/academic_exchange/announcement'),
                meta: {label: '通告公告'}
            },
            {
                path: '/conference_zone',
                name: 'conference_zone',
                component: () => import('@/views/conference_zone'),
                meta: {label: '会议专区'}
            },
            {
                path: '/conference_details',
                name: 'conference_details',
                component: () => import('@/views/conference_zone/conference_details'),
                meta: {label: '会议详情首页'},
                children:[
                    {
                        path: '/con_home',
                        name: 'con_home',
                        component: () => import('@/views/conference_zone/conference_details/con_home'),
                        meta: {label: '会议专区会议首页'}
                    },
                    // {
                    //     path: '/con_home',
                    //     name: 'con_home',
                    //     component: () => import('@/views/conference_zone/conference_details/con_home'),
                    //     meta: {label: '会议专区会议首页'}
                    // },
                    {
                        path: '/con_dongtai',
                        name: 'con_dongtai',
                        component: () => import('@/views/conference_zone/conference_details/con_dongtai'),
                        meta: {label: '会议动态'}
                    },
                    {
                        path: '/con_general',
                        name: 'con_general',
                        component: () => import('@/views/conference_zone/conference_details/con_general'),
                        meta: {label: '会议基本信息'}
                    },
                    {
                        path: '/con_organ',
                        name: 'con_organ',
                        component: () => import('@/views/conference_zone/conference_details/con_organ'),
                        meta: {label: '组织结构'}
                    },
                    {
                        path: '/con_register',
                        name: 'con_register',
                        component: () => import('@/views/conference_zone/conference_details/con_register'),
                        meta: {label: '参会注册'}
                    },
                    {
                        path: '/con_register_detail',
                        name: 'con_register_detail',
                        component: () => import('@/views/conference_zone/conference_details/con_register_detail'),
                        meta: {label: '参会注册'}
                    },
                    {
                        path: '/con_zhusu',
                        name: 'con_zhusu',
                        component: () => import('@/views/conference_zone/conference_details/con_zhusu'),
                        meta: {label: '住宿安排'}
                    },
                    {
                        path: '/con_chat',
                        name: 'con_chat',
                        component: () => import('@/views/conference_zone/conference_details/con_chat'),
                        meta: {label: '联系我们'}
                    },
                ]
            },
            {
                path: '/continuing_education',
                name: 'continuing_education',
                component: () => import('@/views/continuing_education'),
                meta: {label: '继续教育'}
            },
            {
                path: '/member_service',
                name: 'member_service',
                component: () => import('@/views/member_service'),
                meta: {label: '会员服务'}
            },
            {
                path: '/register',
                name: 'register',
                component: () => import('@/views/member_service/register'),
                meta: {label: '注册'}
            },
            {
                path: '/find_password',
                name: 'find_password',
                component: () => import('@/views/member_service/find_password'),
                meta: {label: '找回密码'}
            },
            {
                path: '/person_info',
                name: 'person_info',
                component: () => import('@/views/member_service/person_info'),
                meta: {label: '个人信息'}
            },
            {
                path: '/download_center',
                name: 'download_center',
                component: () => import('@/views/download_center'),
                meta: {label: '下载中心'}
            },
            {
                path: '/download_detail',
                name: 'download_detail',
                component: () => import('@/views/download_center/download_detail'),
                meta: {label: '下载中心'}
            },
            {
                path: '/search_center',
                name: 'search_center',
                component: () => import('@/views/search'),
                meta: {label: '搜索中心'}
            },
        ]
    },
]

// 出错跳转的路由
export const error = [
    // // 404
    // {
    //     path: '/404',
    //     component: () => import('@/views/error/404'),
    //     hidden: true
    // },
    // {
    //     path: '*',
    //     redirect: '/404',
    //     hidden: true
    // }
];

//
// //创建新的router
// const createRouter = () => new Router({
//     mode: 'history',
//     routes: originroutes,
// })
//
// //重置router列表match
// export function resetRouter() {
//     const newRouter = createRouter()
//     router.matcher = newRouter.matcher // the relevant part
// }


// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// 针对 push 方法
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// 针对 replace 方法
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}


//创建路由实例
const router = new Router({
    mode: 'hash',
    routes: originroutes,
})

//
// // 使用 router.beforeEach 注册一个全局前置守卫
// router.beforeEach(async (to, from, next) => {
//     console.log('router', router, 'to', to, 'from', from, 'router');
//     //本地存储有token信息
//     if (window.sessionStorage.getItem("token")) {
//         //去登录页或者去访问首地址/，拦截到首页
//         if (to.path === '/login' ||to.path === '/') {
//             next({path: '/index'})
//         }
//         //不是去登录页
//         else {
//             if (store.state.permission.menuRoutes.length>0){
//                 console.log("vuex中有路由菜单数据放行")
//                 next()
//             }
//             else{
//                 let result=  await  store.dispatch('permission/requestAndSaveRoutes')
//                 // // 清空数据(.match)并添加到路由，这里影响循环
//                 //  resetRouter();
//                 //添加到路由表
//                 router.addRoutes(result);
//                 next({...to, replace: true}) // hack方法 确保addRoutes已完成
//             }
//
//         }
//     }
//     // 没有token
//     else {
//         if (whiteList.indexOf(to.path) !== -1) {
//             // 在免登录白名单，直接进入
//             console.log("没有token，免登陆名单，放行")
//             next()
//         } else {
//             console.log("没有token，不在免登陆名单，拦截到登录页")
//             next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
//         }
//     }
// })


export default router
