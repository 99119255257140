import Vue from 'vue'
import App from './App.vue'
import Cookies from 'js-cookie'
// 抹平浏览器差异
import 'normalize.css/normalize.css'
//全局引入element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


//引入全局公共封装axios
import getApi from './api/request'
//引入vuex
import store from './store'
//引入vue-router
import router from './router'

import "@/assets/styles/style.css"
import "@/assets/styles/animate.min.css"


import "swiper/css/swiper.css";


//使用Element组件
Vue.use(Element, {size: Cookies.get('size') || 'medium',
})


// 全局变量属性定义
Vue.prototype.getApi = getApi


Vue.config.productionTip = false
//实例挂载
new Vue({
    render: (h) => h(App),
    router,
    store,
}).$mount('#app')
