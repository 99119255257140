import Vue from 'vue'
import Vuex from 'vuex'
// import app from './modules/app'
// import tagsView from './modules/tagsView'
// import settings from './modules/settings'
// import permission from './modules/permission'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        // app,
        // settings,
        // permission,
        // tagsView
    },
    getters,
})

export default store
